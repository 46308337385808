.skill-entry-form {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: inherit;

  .add-skill-user {
    display: flex;
    justify-content: space-around;
    margin-bottom: 50px;
    margin-top: 50px;
    min-height: 40px !important;
    width: 800px;
  }

  .add-skill-db {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    margin-right: 20px;
    margin-top: 50px;
  }

  .legend-container {
    margin-bottom: 50px !important;
    width: 40% !important;

    .legend {
      width: inherit;

      .legend-icon {
        margin-left: 5px;
      }

      .legend-icon-hearts {
        color: #ff3000;
      }
    }
  }
}

.add-skills-table-container {
  justify-content: space-around;
  width: 100%;

  .add-skills-table {
    width: 40% !important;
  }
}
