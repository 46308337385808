.container {
  display: flex;
  flex-wrap: wrap;

  .title {
    display: flex;
    flex: 1 1 0;
  }

  .rating {
    flex: 2;
  }
}