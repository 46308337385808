@import "variables.scss";

.header-container {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.87);
  display: flex;
  height: $headerHeight;
  justify-content: space-between;

  .logo {
    background: no-repeat;
    background-image: url("../assets/vazco-logo-negative.png");
    background-size: contain;
    height: 40px;
    margin-left: 50px;
    width: 180px;
  }

  .user-area {
    color: white;
    font-size: 18px;
    font-weight: 800px;
    margin-right: 50px;

    .user-container {
      margin-right: 10px;
    }
  }
}
