@import "variables.scss";

.footer {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.87);
  display: flex;
  height: $footerHeight;
  justify-content: space-between;

  .footer-contact {
    color: white;
    display: flex;
    font-size: 16px;
    font-weight: 700;
    margin-left: 50px;
  }

  .footer-logout {
    margin-right: 50px;
  }
}
