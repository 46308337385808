.charts {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .charts-menu {
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

.chart-summary-tooltip {
  background-color: #8884d8;
  width: 150px;

  .tooltip-label {
    color: white;
    padding: 2px 10px 2px 10px;
  }
}
