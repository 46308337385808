.user-settings {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 20px;

  .user-settings-label {
    font-size: 20px;
    padding-right: 20px;
  }
}
