.not-found {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: inherit;

  .not-found-text {
    color: #1678c2;
    font-size: 24px;
  }
}
