.company-users-table {
  width: 600px !important;

  tbody {
    tr {
      td {
        text-align: center !important;
      }
    }
  }
}
