.login-container {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: inherit;

  .login-button {
    background-color: #1678c2 !important;
    border-radius: 2% !important;
    box-shadow: 5px 10px 8px grey !important;
    color: aliceblue !important;
    display: flex !important;
    font-size: 24px !important;
    height: 100px;
    justify-content: center;
    width: 300px;
  }
}
