.logout {
  background: url('../assets/login-background.png') !important;
  border-radius: 2% !important;
  box-shadow: 2px 5px 4px  grey !important;
  color: #1678c2 !important;
  display: inline !important;
  font-size: 18px !important;
  height: 30px;
  margin-left: 30px;

  span {
    font-weight: 600 !important;
  }
}
